import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Sidebar from '../components/sidebar';
import Img from '../components/img';
import Pagination from '../components/pagination/Pagination';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

// pageContext: gatsby-node.jsのcreatePageメソッドで指定したcontextオブジェクトの値が渡ってくる
// data: index.jsxコンポーネント内で実行した GraphQL の実行結果が渡ってくる
// location: ?
const BlogIndex = ({ pageContext, data, location }) => {
  const sidebarTags = pageContext.tags.sort();
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { nodes } = data.allMarkdownRemark;
  const { current, totalPageNumber } = pageContext;

  return (
    <Layout
      location={location}
      title={siteTitle}
    >
      <Seo
        title={siteTitle}
        location={location}
      />
      <BlogLayoutFlex>
        <BlogLayoutContainer>
          <BlogLayoutInner>
            {
              /* 取得してきた記事をループで表示 */
              nodes.map((post, index) => {
                const title = post.frontmatter.title || post.fields.slug;
                const tags = post.frontmatter.tags.sort();
                return (
                  <BlogCardContents key={index}>
                    <LinkCardArea>
                      <Link
                        to={post.fields.slug}
                        itemProp="url"
                        className="p-0"
                      >
                        <figure>
                          <Img
                            alt={title}
                            image={post.frontmatter.hero}
                            className="img-fluid"
                          />
                        </figure>
                        <h2>{title}</h2>
                        <BlogDetailContainer>
                          <DetailTimeArea>
                            <FontAwesomeIcon icon={faClock} />
                            <time>{post.frontmatter.date}</time>
                          </DetailTimeArea>
                        </BlogDetailContainer>
                      </Link>
                    </LinkCardArea>
                    <TagArea>
                      {tags.map((tag, index) => {
                        return (
                          <Link
                            key={`tag${index}`}
                            to={`/tags/${tag}/`}
                          >
                            {tag}
                          </Link>
                        );
                      })}
                    </TagArea>
                  </BlogCardContents>
                );
              })
            }
          </BlogLayoutInner>
          <StyledPagination>
            {/* ページネーション */}
            <Pagination
              totalPageNumber={totalPageNumber}
              current={current}
              type="page"
            />
          </StyledPagination>
        </BlogLayoutContainer>
        {/* サイドバー */}
        <Sidebar sidebarTags={sidebarTags} />
      </BlogLayoutFlex>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query ($limit: Int!, $skip: Int!, $draft: [Boolean]!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: $limit # $limi: 1ページに表示する記事の数
      skip: $skip # $skip: offset値（全レコードの内、offset番目からlimi件数分取得する）
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          pagetype: { eq: "blog" }
          draft: { in: $draft } # 下書きのページは表示させない
        }
      }
    ) {
      totalCount
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          hero
          tags
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "images" }, relativePath: { regex: "/thumbnails/" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [WEBP], placeholder: BLURRED, quality: 70)
          }
        }
      }
    }
  }
`;

// Paginationコンポーネント用のスタイルを定義
const StyledPagination = styled.div`
  margin-top: 24px;
`;

const LinkCardArea = styled.div`
  @media (min-width: 1200px) {
    min-height: 250px;
  }

  & > a {
    display: block;
    color: #5a5a5a;
    padding: 0.5rem;
    transition: all 0.3s;
    &:hover {
      color: #5a5a5a;
      opacity: 0.7;
    }
    &:hover .gatsby-image-wrapper {
      transform: scale(1.05);
    }

    &:hover figure::after {
      font-size: 90%;
      letter-spacing: 2px;
      opacity: 0.7;
    }
  }
`;

const BlogLayoutFlex = styled.div`
  display: block;

  @media (min-width: 1200px) {
    display: flex;
  }
`;

const BlogLayoutContainer = styled.div`
  padding-top: 48px;
  padding-bottom: 48px;
  @media (min-width: 1200px) {
    width: 75%;
    flex: 0 0 auto;
    margin-top: 32px;
  }
`;

const BlogLayoutInner = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    width: 100%;
    flex: 0 0 auto;
  }
`;

const BlogDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 1200px) and (max-width: 1200px) {
    display: block;
  }
`;

const DetailTimeArea = styled.div`
  & time {
    display: inline-block;
    margin-left: 0.2rem;
    font-size: 0.9rem;
  }
`;

const TagArea = styled.div`
  display: flex;
  font-weight: 700;

  & p {
    margin-left: 1rem;
    &:first-child {
      margin-left: 0px;
    }
  }

  & > a {
    display: inline-block;
    padding: 4px 12px;
    background-color: #0a0b37;
    color: #ffffff;
    font-size: 12px;
    margin-right: 8px;
    transition: all 0.3s;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      color: #ffffff;
      opacity: 0.6;
    }
  }
`;

const BlogCardContents = styled.article`
  padding: 0.3rem;
  margin-bottom: 1rem;

  @media (min-width: 1200px) {
    width: 33.33333%;
    flex: 0 0 auto;
    padding: 0.7rem;
  }

  & figure {
    position: relative;
    // ホバー時に拡大した画像ではみ出した部分を切り取る
    overflow: hidden;

    & .gatsby-image-wrapper {
      transition: all 0.3s;
    }

    &::after {
      content: 'READ MORE';
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 50%;
      color: #fff;
      opacity: 0;
      letter-spacing: 1.5px;
      transition: all 0.3s;
    }
  }

  & h2 {
    font-size: 1.3rem;
    margin-bottom: 1.3rem;
  }
`;
