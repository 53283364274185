import React, { useState, useEffect, useCallback } from 'react'; // useCallback を追加
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import TextHighlighter from './text-highlighter';
import styled from 'styled-components';

const SearchResult = props => {
  // 全記事データ取得
  const blogData = useStaticQuery(graphql`
    query SearchData {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: {
            pagetype: { eq: "blog" }
            draft: { in: false } # 下書きのページは検索しない
          }
        }
        limit: 1000
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY-MM-DD")
              title
              tags
              cate
              description
              hero
              draft
            }
          }
        }
      }
    }
  `);

  // 記事のデータ格納
  const [data, setData] = useState([]);
  // 表示・非表示の切り替え
  const [isShowSearchArea, setIsShowSearchArea] = useState(false);
  // 検索でマッチしたものを格納する
  const [result, setResult] = useState([]);

  // 記事フィルター処理
  const blogSearch = useCallback(() => {
    const value = props.value.toLowerCase();
    const filterData = data.filter(item => {
      let tags = '';
      let category = '';
      let description = '';

      if (item.frontmatter.tags) {
        tags = item.frontmatter.tags.join(' ').toLowerCase();
      }

      if (item.frontmatter.description) {
        description = item.frontmatter.description.toLowerCase();
      }

      if (item.frontmatter.cate) {
        category = item.frontmatter.cate.toLowerCase();
      }

      const targetValue = `
        ${item.frontmatter.title.toLowerCase()}
        ${category}
        ${tags}
        ${description}
      `;

      // 記事から抽出した文字列に検索キーワードが含まれている記事データを返す
      return targetValue.indexOf(value) !== -1;
    });

    setResult(filterData);
  }, [props.value, data]); //  依存配列にprops.valueとdataを含める

  /**************************************
   * 記事取得処理
   * 初回レンダリング時のみ実行
   **************************************/
  useEffect(() => {
    // Array.prototype.map() expects a return value from arrow function
    // mapの戻り値を受けるように修正
    const posts = blogData.allMarkdownRemark.edges.map(item => item.node);
    setData(posts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**************************************
   * 検索結果の表示・非表示切り替え
   **************************************/
  useEffect(() => {
    if (props.value === '') {
      // 検索ボックスの値が空のときは検索結果のデータを空にする
      setResult([]);
      setIsShowSearchArea(false);
    } else {
      setIsShowSearchArea(true);
    }

    // props.valueにのみ関心を持つuseEffectにする
    // props.valueが変化した時のみ変更を通知してこの中の処理を実行する
    // ここでは props.focus の値に対しても実行する
  }, [props.value, props.focus]);

  /**************************************
   * 検索処理
   * テキストボックスの値が変化したときに実行される
   **************************************/
  useEffect(() => {
    blogSearch();
    // props.valueにのみ関心を持つuseEffectにする
    // props.valueが変化した時のみ変更を通知してこの中の処理を実行する
  }, [props.value, blogSearch]);

  return (
    <>
      <ResultList>
        <div className="result-inner">
          {isShowSearchArea && (
            <p>
              <b>{result.length}</b>件ヒットしました
            </p>
          )}

          {isShowSearchArea && (
            <ul>
              {result.map(item => {
                return (
                  <li key={item.fields.slug}>
                    <Link to={item.fields.slug}>
                      <time>{item.frontmatter.date}</time>
                      <TextHighlighter
                        str={item.frontmatter.title}
                        includes={props.value}
                      />
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </ResultList>
    </>
  );
};

export default SearchResult;

const ResultList = styled.div`
  .result-inner {
    margin-top: 20px;
    margin-bottom: 50px;

    p {
      text-align: center;
    }
  }
  ul {
    padding-left: 0px;
    margin-top: 20px;
    background: #eeeeee;
    max-height: 300px;
    overflow: auto;
    margin-bottom: 50px;
    border-radius: 10px;
  }
  li {
    padding: 10px 20px;
    margin-bottom: 5px;
    font-weight: bold;
    line-height: 1.8;
  }
  li a {
    color: var(--color-blue);
  }

  li time {
    display: block;
    font-weight: normal;
    font-size: 1.2rem;
  }

  @media (min-width: 1200px) {
    li {
      text-indent: -100px;
      margin-left: 100px;
    }
    li a {
      text-decoration: underline;
    }

    li time {
      font-size: 1rem;
      width: 100px;
      text-indent: 0;
      display: inline-block;
      font-weight: bold;
      color: var(--font-color);
    }
  }
`;
