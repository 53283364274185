import * as React from 'react';
import { Link } from 'gatsby';
import Bio from './bio';
import Search from './search';
import styled from 'styled-components';

const Sidebar = ({ sidebarTags }) => {
  return (
    <SidebarContainer>
      <PContainer>
        <PInner>
          <Bio />
        </PInner>
      </PContainer>

      <Search></Search>

      <PContainer>
        <PInner>
          <h3>タグ</h3>
          <ListContainer>
            {sidebarTags.map((tag, index) => (
              <LinkItem key={index}>
                <Link to={`/tags/${tag}`}>
                  <div>
                    <span>{tag}</span>
                  </div>
                </Link>
              </LinkItem>
            ))}
          </ListContainer>
        </PInner>
      </PContainer>
    </SidebarContainer>
  );
};
export default Sidebar;

const ListContainer = styled.ul`
  padding: 16px 0px;
`;

const SidebarContainer = styled.div`
  padding-top: 48px;
  padding-bottom: 48px;

  @media (min-width: 1200px) {
    width: 25%;
    flex: 0 0 auto;
    margin-top: 32px;
  }
`;

const PContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: 1200px) {
    margin-left: 16px;
    padding: 16px;
  }
`;

const PInner = styled.div`
  padding: 16px;
`;

const LinkItem = styled.li`
  list-style: none;
  display: inline-block;

  & div {
    cursor: pointer;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    line-height: 1rem;
    letter-spacing: 0.1em;
    padding: 4px 12px;
    background: #0a0b37;
    color: #ffffff;
    margin: 8px;
    transition: opacity 0.3s;

    &:hover {
      color: #ffffff;
      opacity: 0.6;
    }
  }
`;
